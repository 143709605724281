import { useState, useEffect, useContext } from "react";
import "../styles/Superadmin.css";
import useWebSocket from "react-use-websocket";
import { Session } from "../Param";

const socketUrl = process.env.REACT_APP_SOCKET_URL;

function Superadmin({ setLoad }) {
  const [session] = useContext(Session);
  const [newAdmin, setNewAdmin] = useState({
    id_vr: "",
    asso_name: "",
    superadmin: false,
  });
  const [newActivity, setNewActivity] = useState({ name: "", description: "" });
  const [activities, setActivities] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAdminFormOpen, setIsAdminFormOpen] = useState(false);
  const [isActivityFormOpen, setIsActivityFormOpen] = useState(false);

  const { lastMessage, sendMessage } = useWebSocket(socketUrl, {
    onOpen: () => sendMessage(JSON.stringify({ id: session.login })),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => true,
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER}/api/activity/all`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Credentials": true,
      },
      credentials: "include",
    }).then(async (res) => {
      setActivities(await res.json());
    });

    fetch(`${process.env.REACT_APP_SERVER}/api/admin/all`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Credentials": true,
      },
      credentials: "include",
    }).then(async (res) => {
      setAdmins(await res.json());
    });
  }, []);

  useEffect(() => {
    if (lastMessage?.data && admins) {
      let update = JSON.parse(lastMessage.data);
      if (admins.some((admin) => admin.id_vr === update.id_vr)) {
        setAdmins(admins.filter((admin) => admin.id_vr !== update.id_vr));
      } else {
        let admins_update = [...admins, update];
        setAdmins(admins_update);
      }
    }
    if (lastMessage?.data && activities) {
      let update = JSON.parse(lastMessage.data);
      if (activities.some((activity) => activity.name === update.name)) {
        setActivities(
          activities.filter((activity) => activity.name !== update.name)
        );
      } else {
        let activities_update = [...activities, update];
        setActivities(activities_update);
      }
    }
  }, [lastMessage]);

  const AdminSubmit = () => {
    if (!newAdmin.id_vr.length || !newAdmin.asso_name.length) {
      alert(
        "Veuillez bien renseigner l'id de l'administrateur et choisir une association !"
      );
    } else {
      setIsAdminFormOpen(false);
      setLoading(true);
      fetch(`${process.env.REACT_APP_SERVER}/api/admin/create`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Access-Control-Allow-Credentials": true,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(newAdmin),
      })
        .then((res) => {
          if (res.status === 200) {
            alert("L'administrateur a été ajouté avec succès !");
            setLoad(true);
          } else {
            alert("Une erreur s'est produite!");
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    }
  };

  const handleAdminDelete = (admin) => {
    setLoading(true);
    fetch(`${process.env.REACT_APP_SERVER}/api/admin/delete/${admin.id_vr}`, {
      method: "DELETE",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Credentials": true,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((res) => {
        if (res.status === 200) {
          alert("L'administrateur a été supprimé avec succès !");
          setLoad(true);
        } else {
          alert("Une erreur s'est produite lors de la suppression.");
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  const ActivitySubmit = () => {
    if (!newActivity.name.length || !newActivity.description.length) {
      alert(
        "Veuillez bien renseigner le nom de l'association et la description !"
      );
    } else if (
      activities.some((activity) => activity.name === newActivity.name)
    ) {
      alert("Il y a déjà une activité pour cette association");
    } else {
      setIsActivityFormOpen(false);
      setLoading(true);
      fetch(`${process.env.REACT_APP_SERVER}/api/activity/create`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Access-Control-Allow-Credentials": true,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(newActivity),
      })
        .then((res) => {
          if (res.status === 200) {
            alert("L'activité a été ajouté avec succès !");
            setLoad(true);
          } else {
            alert("Une erreur s'est produite !");
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    }
  };

  const handleActivityDelete = (activity) => {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_SERVER}/api/activity/delete/${activity.name}`,
      {
        method: "DELETE",
        mode: "cors",
        headers: {
          "Access-Control-Allow-Credentials": true,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    )
      .then((res) => {
        if (res.status === 200) {
          alert("L'activité a été supprimé avec succès !");
          setLoad(true);
        } else {
          alert("Une erreur s'est produite lors de la suppression.");
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  return (
    <div className="create-admin-wrap">
      <div className="activities-container">
        <h2 className="activities-title">Liste des admins :</h2>
        {admins &&
          admins.map((admin) => (
            <div key={admin.id_vr} className="admin">
              <p className="activity-text">Id VR : {admin.id_vr}</p>
              <p className="activity-text center">
                Association : {admin.asso_name}
              </p>
              <p className="activity-text center">
                Superadmin : {admin.superadmin === true ? "Oui" : "Non"}
              </p>
              <button
                className="button delete"
                onClick={() => handleAdminDelete(admin)}
              >
                <span className="text size-16">Supprimer</span>
                <span className="icon delete">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
                  </svg>
                </span>
              </button>
            </div>
          ))}
        {!isAdminFormOpen && (
          <div className="add-activity-button">
            <button
              className="button add"
              onClick={() => setIsAdminFormOpen(true)}
            >
              <span className="text size-12">Ajouter un administrateur</span>
              <span className="icon add">
                <svg
                  width="64px"
                  height="64px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#ffffff"
                  strokeWidth="0.9120000000000001"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M13 3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44772 11 3V11H3C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H11V21C11 21.5523 11.4477 22 12 22C12.5523 22 13 21.5523 13 21V13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11H13V3Z"
                      fill="#ffffff"
                    ></path>
                  </g>
                </svg>
              </span>
            </button>
          </div>
        )}
        {isAdminFormOpen && (
          <div className="create-admin-container margin-admin">
            <h1 className="create-admin-title">Ajouter un administrateur</h1>
            <div className="create-admin-container">
              <label>Identifiant ViaRézo de l'administrateur</label>
              <input
                type="text"
                placeholder="ID admin"
                onChange={(e) =>
                  setNewAdmin({ ...newAdmin, id_vr: e.target.value })
                }
                disabled={loading}
              />
            </div>
            <div className="create-admin-container">
              <label>Association de l'administrateur</label>
              <select
                name="association"
                disabled={loading}
                defaultValue="default"
                onChange={(e) =>
                  setNewAdmin({ ...newAdmin, asso_name: e.target.value })
                }
              >
                <option value="default" disabled>
                  Choisir une association
                </option>
                {activities
                  ?.filter(
                    (asso) =>
                      asso.name !== "En attente d'activité" &&
                      asso.name !== "Fin du jeu"
                  )
                  .map((asso) => (
                    <option key={asso.name}>{asso.name}</option>
                  ))}
              </select>
            </div>
            <div className="create-admin-superadmin">
              <input
                className="create-admin-superadmin-input"
                type="checkbox"
                disabled={loading}
                name="Superadmin"
                onChange={(e) =>
                  setNewAdmin({ ...newAdmin, superadmin: e.target.checked })
                }
              />
              <label>Superadmin</label>
            </div>
            <button onClick={AdminSubmit} disabled={loading}>
              Ajouter l'administrateur
            </button>
          </div>
        )}
      </div>

      <div className="activities-container">
        <h2 className="activities-title">Liste des activités :</h2>
        {activities &&
          activities.map((activity) => (
            <div
              key={activity.name}
              className="activity-container border-activity"
            >
              <div className="activity">
                <p className="activity-text">Asso : {activity.name}</p>
                <p className="activity-text">
                  Description : {activity.description}
                </p>
              </div>
              <button
                className="button delete"
                onClick={() => handleActivityDelete(activity)}
              >
                <span className="text">Supprimer</span>
                <span className="icon delete">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"></path>
                  </svg>
                </span>
              </button>
            </div>
          ))}
        {!isActivityFormOpen && (
          <div className="add-activity-button">
            <button
              className="button add"
              onClick={() => setIsActivityFormOpen(true)}
            >
              <span className="text">Ajouter une activité</span>
              <span className="icon add">
                <svg
                  width="64px"
                  height="64px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#ffffff"
                  strokeWidth="0.9120000000000001"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M13 3C13 2.44772 12.5523 2 12 2C11.4477 2 11 2.44772 11 3V11H3C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H11V21C11 21.5523 11.4477 22 12 22C12.5523 22 13 21.5523 13 21V13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11H13V3Z"
                      fill="#ffffff"
                    ></path>
                  </g>
                </svg>
              </span>
            </button>
          </div>
        )}
        {isActivityFormOpen && (
          <div className="activity-container">
            <div className="activity">
              <div className="asso-input">
                <label>Nom de l'association :</label>
                <input
                  type="text"
                  onChange={(e) =>
                    setNewActivity({ ...newActivity, name: e.target.value })
                  }
                  disabled={loading}
                />
              </div>
              <div className="description-input">
                <label>Description de l'activité :</label>
                <textarea
                  rows={3}
                  cols={100}
                  onChange={(e) =>
                    setNewActivity({
                      ...newActivity,
                      description: e.target.value,
                    })
                  }
                  disabled={loading}
                />
              </div>
            </div>
            <button className="button send" onClick={ActivitySubmit}>
              <span className="text">Valider</span>
              <span className="icon send">
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M20 4L3 11L10 14L13 21L20 4Z"
                      stroke="#ffffff"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                    ></path>
                  </g>
                </svg>
              </span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Superadmin;
